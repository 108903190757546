import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Firefox Voice Beta 2 (tack bittin): `}<a parentName="li" {...{
          "href": "https://community.mozilla.org/activities/test-firefox-voice/"
        }}>{`https://community.mozilla.org/activities/test-firefox-voice/`}</a></li>
      <li parentName="ul">{`Firefox 74 ute: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/74.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/74.0/releasenotes/`}</a></li>
      <li parentName="ul">{`Stanford vill låna din dator för att studera coronaviruset: `}<a parentName="li" {...{
          "href": "https://www.theverge.com/2020/3/2/21161131/folding-home-volunteers-researchers-coronavirus"
        }}>{`https://www.theverge.com/2020/3/2/21161131/folding-home-volunteers-researchers-coronavirus`}</a></li>
      <li parentName="ul">{`Nextcloud växer:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://nextcloud.com/blog/nextcloud-doubles-order-intake-and-customer-base-remains-profitable-and-independent/"
            }}>{`https://nextcloud.com/blog/nextcloud-doubles-order-intake-and-customer-base-remains-profitable-and-independent/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://trends.google.com/trends/explore?q=nextcloud,owncloud,seafile,filecloud,sharefile"
            }}>{`https://trends.google.com/trends/explore?q=nextcloud,owncloud,seafile,filecloud,sharefile`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Nytt säkerhetshål med Intel: `}<a parentName="li" {...{
          "href": "https://lviattack.eu/"
        }}>{`https://lviattack.eu/`}</a><ul parentName="li">
          <li parentName="ul">{`Tidigare: Meltdown, Foreshadow, ZombieLoad, RIDL and Fallout`}</li>
        </ul></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`NymphCast: `}<a parentName="li" {...{
          "href": "https://github.com/MayaPosch/NymphCast"
        }}>{`https://github.com/MayaPosch/NymphCast`}</a></li>
      <li parentName="ul">{`TimeShift på Linux: `}<a parentName="li" {...{
          "href": "https://github.com/teejee2008/timeshift"
        }}>{`https://github.com/teejee2008/timeshift`}</a></li>
      <li parentName="ul">{`Storybook: `}<a parentName="li" {...{
          "href": "https://storybook.js.org/"
        }}>{`https://storybook.js.org/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Har flashat PostmarketOS + Phosh`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Rocket League fungerar!`}</li>
      <li parentName="ul">{`Path of Exile strular :(`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Kanske Spleen i loggan (tack Isak): `}<a parentName="li" {...{
          "href": "https://github.com/fcambus/spleen"
        }}>{`https://github.com/fcambus/spleen`}</a></li>
      <li parentName="ul">{`Sidor för att följa Trevlighetspoäng och Utmaningar`}</li>
    </ul>
    <h3>{`Contributor-ligan`}</h3>
    <ul>
      <li parentName="ul">{`Alex: 1 + 3 = 4 brons`}</li>
      <li parentName="ul">{`Seb: 1 brons`}<ul parentName="li">
          <li parentName="ul">{`TrevligPodcastPlayer`}</li>
        </ul></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      